import { render, staticRenderFns } from "./twoLayout.vue?vue&type=template&id=7af1652a&scoped=true&"
import script from "./twoLayout.vue?vue&type=script&lang=js&"
export * from "./twoLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7af1652a",
  null
  
)

export default component.exports