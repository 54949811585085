<template>

  <router-view></router-view>
</template>

<script>
export default {
  name: "twoLayout"
}
</script>

<style scoped>

</style>